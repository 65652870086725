import { Injectable } from '@angular/core';
import { Emittable, Emitter } from '@ngxs-labs/emitter';
import { CognitoUserSession } from 'amazon-cognito-identity-js';
import { Auth } from 'aws-amplify';
import { from, map, Observable } from 'rxjs';
import { PermissionsControlState } from 'src/app/secure/permissions/permissions-control.state';
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  email = '';
  @Emitter(PermissionsControlState.updatekeyUser) updateKeyUser:
    | Emittable<any>
    | undefined;
  async getEmail() {
    await Auth.currentSession().then((user) => {
      const session = user.getIdToken();
      this.email = session.payload['email'];
    });

    return this.email;
  }

  isAuthenticated(): Promise<boolean> {
    const isAuthenticated = Auth.currentAuthenticatedUser()
      .then(async () => {
        const keyUser = await this.isKeyUser();
        this.updateKeyUser?.emit(keyUser);
        return true;
      })
      .catch(() => {
        return false;
      });
    return isAuthenticated;
  }
  async isKeyUser() {
    return await Auth.currentSession().then((session) => {
      const idToken = session.getIdToken();
      const roles = this.getRoles(idToken.payload['custom:role']);
      return roles.includes('KeyUser');
    });
  }
  getRoles(roleString: string): string[] {
    return roleString
      .replace('[', '')
      .replace(']', '')
      .split(',')
      .map((role) => role.trim());
  }

  public getToken(): Observable<any> {
    return from(Auth.currentSession()).pipe(
      map((session: CognitoUserSession) => session.getIdToken().getJwtToken())
    );
  }

  logout() {
    Auth.signOut();
  }
}
