import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { Emittable, Emitter } from '@ngxs-labs/emitter';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import * as AWS from 'aws-sdk';
// TODO Ines --> Feedback State is used, therefore also put that modal component into the feedback module
import { FeedbackState } from './../../secure/feedback/feedback.state';
import { Auth } from 'aws-amplify';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
export class ModalComponent implements OnInit {
  @Select(FeedbackState.feedbackResponse) feedbackResponse$:
    | Observable<any>
    | undefined;
  form = new UntypedFormGroup({});
  model = {};
  id = '';
  dataArray?: any[];
  saveButtondisabled = true;
  imageUrl: string | undefined;  // TODO Ines: Either put that modal component into the "Feedback" module
  // or don't use a state here to be a generic component (in this case @Input and @Output can be used)
  @Emitter(FeedbackState.feedbackResponseEmitter) feedbackResponseEmitter:
    | Emittable<any>
    | undefined;
  public safeUrl : SafeUrl='';

  fields: FormlyFieldConfig[] = [
    {
      fieldGroup: [],
    },
  ];
  lastRow: any;

  constructor(
    private sanitizer: DomSanitizer,
    public dialogRef: MatDialogRef<ModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }
  onSelected() {
    const item = this.getItem();
    const numberOfFieldsinModel = Object.keys(this.model).length + 2;
    if (Object.keys(item).length == numberOfFieldsinModel) {
      this.saveButtondisabled = false;
    }
  }
  save() {
    const item = this.getItem();
    this.changeRawValue(item);
    this.feedbackResponseEmitter?.emit({
      dataframe: this.dataArray,
      lastrow: this.lastRow,
    });
  }
  getItem() {
    let result = {};
    if (this.dataArray) {
      for (const item of this.dataArray) {
        if (item['id']) {
          if (item['id']['key'] == this.id) {
            result = item;
            break;
          }
        }
      }
    }
    return result;
  }
  changeRawValue(item: any) {
    const field = 'Photo';
    const id = 'id';
    Object.assign(this.model, {
      [field]: item[field as keyof typeof item],
      [id]: item[id as keyof typeof item],
    });
    if (this.dataArray) {
      this.dataArray.splice(this.dataArray.indexOf(item), 1, this.model);
    }
  }
  downloadmage(){
    console.log('url*', this.data['imagePath'])
    const delimiter = '://'; // Delimiter between the protocol and the rest of the URL
    const parts = this.data['imagePath'].split(delimiter); // Split the URL into parts using the delimiter
  
    if (parts.length !== 2 || !parts[1]) {
      throw new Error('Invalid S3 URL format');
    }
  
    const [_, path] = parts; // Ignore the protocol part and extract the path
    const [bucket, ...keyParts] = path.split('/'); // Split the path into bucket and key parts
  
    const key = keyParts.join('/');
    console.log('url*', this.data['imagePath'])
    console.log('bucket',bucket)
    console.log('key',key)
    Auth.currentCredentials()
    .then(credentials => {
      // Configure AWS SDK with Amplify credentials
      AWS.config.update({
        region: 'eu-west-1',
        credentials: credentials,
      });
  
      const s3 = new AWS.S3();
    if(this.imageUrl){
      const params = {
        Bucket: bucket,
        Key: key,
      };


      s3.getObject(params, (err, data) => {
        if (err) {
          console.log(err);
        } else {
          const imageBlob = new Blob([data.Body as BlobPart], { type: 'image/jpeg' });
          const imageURL = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(imageBlob))
          console.log('Image URL:', imageURL);
          this.safeUrl=imageURL
          console.log("image url",this.imageUrl)
          console.log("imageBlob",imageBlob)
          // Use the imageUrl to display the image in the browser
        }
      });}
    })
    .catch(error => {
      console.log('Error retrieving credentials:', error);
    });
  }
  ngOnInit() {
    this.imageUrl = this.data['url'];
    this.id = this.data['id'];
    this.downloadmage();
    this.fields[0].fieldGroup = JSON.parse(
      this.data['structure']['fieldstoset']
    );
    this.feedbackResponse$?.subscribe((res) => {
      if (res) {
        this.dataArray = JSON.parse(JSON.stringify(res.dataframe));
        if (res.lastrow) {
          this.lastRow = JSON.parse(JSON.stringify(res.lastrow));
        }
      }
    });
  }
}
