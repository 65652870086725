import { Injectable } from '@angular/core';
import {
  CameraResponseDuncan,
  CameraResultDuncan,
  CameraRunDuncan,
} from '../types/camera-response-duncan.type';

@Injectable({ providedIn: 'root' })
export class DuncanCameraResponseMapperService {
  constructor() {}

  map(restResponse: any): CameraResponseDuncan {
    const dataframe = restResponse.dataframe;
    const nokImagePaths = [...restResponse.noks.imgpaths];
    return {
      barcode: dataframe.barcode[0],
      firstDate: dataframe.date[0],
      firstChamber: dataframe.chamber[0],
      vehicleType: dataframe.vehicletype[0],
      runs: this.mapRuns(dataframe, nokImagePaths),
    };
  }

  private mapRuns(dataframe: any, nokImagePaths: string[]): CameraRunDuncan[] {
    const runCount = dataframe.barcode.length;
    const uniqueCameraPrefixes = this.getUniqueCamPrefixes(dataframe);
    const runs: CameraRunDuncan[] = [];
    for (let i = 0; i < runCount; i++) {
      runs.push({
        chamber: dataframe.chamber[i],
        date: dataframe.date[i],
        timestamp: dataframe.timestamp[i],
        camResults: this.mapCamResults(
          dataframe,
          uniqueCameraPrefixes,
          i,
          nokImagePaths
        ),
      });
    }
    return runs;
  }

  private getUniqueCamPrefixes(dataframe: any): string[] {
    let uniqueCameraPrefixes: string[] = [];
    for (const key of Object.keys(dataframe)) {
      if (key.startsWith('cam')) {
        // get unique camera prefixes of all fields beginning with "cam"
        // e.g. cam1_result, cam2_threshold, ...
        uniqueCameraPrefixes.push(key.split('_')[0]); // keys begin e.g. with "cam1_", "cam2_", ...
      }
    }
    uniqueCameraPrefixes = [...new Set(uniqueCameraPrefixes)].sort((a, b) =>
      a.localeCompare(b)
    );

    return uniqueCameraPrefixes;
  }

  private mapCamResults(
    dataframe: any,
    uniqueCamPrefixes: string[],
    runIndex: number,
    nokImagePaths: string[]
  ): CameraResultDuncan[] {
    const camResults: CameraResultDuncan[] = [];
    uniqueCamPrefixes.forEach((camPrefix) => {
      const camResult = {
        camName: camPrefix,
        camDisplayName:
          camPrefix.slice(0, 3).toUpperCase() + ' ' + camPrefix.slice(3),
        imagePath: this.getImagePath(
          dataframe,
          camPrefix,
          runIndex,
          nokImagePaths
        ),
        result: dataframe[camPrefix + '_result'][runIndex],
        threshold: dataframe[camPrefix + '_threshold'][runIndex],
      };

      camResults.push(camResult);
    });

    camResults.sort((a, b) => a.camName.localeCompare(b.camName));
    return camResults;
  }

  private getImagePath(
    dataframe: any,
    camPrefix: string,
    runIndex: number,
    nokImagePaths: string[]
  ): string {
    const result: boolean = dataframe[camPrefix + '_result'][runIndex];
    if (result === true) {
      // maps local Windows path (with '\\') to S3 path
      return (
        'drx_teststation1/ingoing/' +
        dataframe[camPrefix + '_imagepath'][runIndex].replace(/\\/g, '/')
      );
    } else {
      // use NOK imagepath if result = false
      // NOK path looks like:
      // 'drx_teststation1/ingoing/ErrorImages/20220815/BK46/G06/CAM1_Left_K70708911BK4601_20220815024221_Result.png'
      const index = nokImagePaths.findIndex((nokPath) => {
        // cameraSubpath would be 'CAM1_Left_K70708911BK4601_20220815024221_Result.png'
        const cameraSubpath = nokPath.split('/')[6];
        // use first part of string splitted by '_'
        const cameraPrefixInSubpath = cameraSubpath.split('_')[0];
        return cameraPrefixInSubpath.toLowerCase() === camPrefix.toLowerCase();
      });

      if (index === -1) {
        console.error(
          'No NOK image was found for Camera:',
          camPrefix,
          ' and Run Index:',
          runIndex
        );
        return '';
      } else {
        const deletedNokPath = nokImagePaths.splice(index, 1)[0];
        return deletedNokPath;
      }
    }
  }
}
