import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import '@fortawesome/fontawesome-free/js/all.js';
import { FieldType } from '@ngx-formly/core';
import { Storage } from 'aws-amplify';
import { ModalComponent } from './../modal/modal.component';
@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'image-link-type',
  template: `
    <p>
      <a style="color: #0097ac" (click)="getImagePath()">
        <i
          style="font-size: 30px; color: #0097ac"
          class="fa-solid fa-square-up-right"
        ></i>
      </a>
    </p>
  `,
})
export class ImageLinkTypeComponent extends FieldType {
  constructor(public dialog: MatDialog) {
    super();
  }
  onClick() {
    this.getImagePath();
  }
  getImagePath() {
    console.log('this.to',this.to)
    const imagePath = this.to['url'];
    let bucketName = imagePath.replace('s3://', '');
    const array = bucketName.split('/');
    bucketName = array[0];
    Storage.get(imagePath.replace('s3://' + bucketName + '/', ''), {
      bucket: bucketName,
    })
      .then((data) => {
        const dialogRef = this.dialog.open(ModalComponent, {
          width: '600px',
          data: { url: data, imagePath:imagePath, structure: this.to, id: this.to['id'] },
        });
        dialogRef.afterClosed().subscribe((result) => {
          console.log('closed');
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }
}
