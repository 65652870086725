import { Injectable, Injector } from '@angular/core';
import {
  Emittable,
  Emitter,
  EmitterAction,
  Receiver,
} from '@ngxs-labs/emitter';
import { Selector, State, StateContext } from '@ngxs/store';
import { map, switchMap, tap } from 'rxjs';
import { DuncanCameraResponseMapperService } from './services/duncan-camera-response-mapper.service';
import { TraceabilityService } from './services/traceability.service';
import { CameraResponseDuncan } from './types/camera-response-duncan.type';

export interface TraceabilityModel {
  loading: boolean;
  barcode: string;
  cameraResponseDuncan?: CameraResponseDuncan;
}

@State<TraceabilityModel>({
  name: 'traceability',
  defaults: {
    loading: true,
    barcode: '',
  },
})
@Injectable()
export class TraceabilityState {
  static traceabilityService: TraceabilityService;
  static duncanCamMapperService: DuncanCameraResponseMapperService;

  @Emitter(TraceabilityState.setLoading)
  private static emitLoading: Emittable<void>;

  @Emitter(TraceabilityState.setLoadingFinished)
  private static emitLoadingFinished: Emittable<void>;

  @Emitter(TraceabilityState.loadCameraResponseDuncan)
  private static emitLoadCamResponseDuncan: Emittable<void>;

  constructor(injector: Injector) {
    TraceabilityState.traceabilityService =
      injector.get<TraceabilityService>(TraceabilityService);
    TraceabilityState.duncanCamMapperService =
      injector.get<DuncanCameraResponseMapperService>(
        DuncanCameraResponseMapperService
      );
  }

  @Selector()
  static barcode(state: TraceabilityModel): string {
    return state.barcode;
  }

  @Selector()
  static loading(state: TraceabilityModel): boolean {
    return state.loading;
  }

  @Selector()
  static cameraResponseDuncan(
    state: TraceabilityModel
  ): CameraResponseDuncan | undefined {
    return state.cameraResponseDuncan;
  }

  @Receiver()
  public static setBarcode(
    { patchState }: StateContext<TraceabilityModel>,
    { payload }: EmitterAction<string>
  ) {
    patchState({ barcode: payload });
    this.emitLoadCamResponseDuncan.emit();
  }

  @Receiver()
  public static setLoading({ patchState }: StateContext<TraceabilityModel>) {
    patchState({ loading: true });
  }

  @Receiver()
  public static setLoadingFinished({
    patchState,
  }: StateContext<TraceabilityModel>) {
    patchState({ loading: false });
  }

  @Receiver()
  public static loadCameraResponseDuncan({
    patchState,
    getState,
  }: StateContext<TraceabilityModel>) {
    return this.emitLoading.emit().pipe(
      switchMap(() =>
        this.traceabilityService.searchByBarcode(getState().barcode!)
      ),
      map((response) => this.duncanCamMapperService.map(response)),
      tap((cameraResponseDuncan) => patchState({ cameraResponseDuncan })),
      switchMap(() => this.emitLoadingFinished.emit())
    );
  }
}
