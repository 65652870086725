import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { GroupRightsType } from './types/group-rights-type';

@Injectable({ providedIn: 'root' })
export class PermissionsService {
  constructor(private httpClient: HttpClient) {}
  getPermissions(email: string): Observable<GroupRightsType> {
    return this.httpClient.get<GroupRightsType>(
      `${environment.restUrlPermissions}/users/${email}/rights`
    );
  }
  getGroupsList(): Observable<string[]> {
    return this.httpClient.get<string[]>(
      `${environment.restUrlPermissions}/groups`
    );
  }
  getUsersInGroup(group: string): Observable<string[]> {
    return this.httpClient.get<string[]>(
      `${environment.restUrlPermissions}/groups/${group}`
    );
  }
  getAllUsersList(): Observable<string[]> {
    return this.httpClient.get<string[]>(
      `${environment.restUrlPermissions}/users`
    );
  }
  addUserToGroup(user: string, group: string): Observable<any> {
    return this.httpClient.put<any>(
      `${environment.restUrlPermissions}/groups/${group}/${user}`,
      {}
    );
  }
  deleteUserFromGroup(user: string, group: string): Observable<any> {
    return this.httpClient.delete<any>(
      `${environment.restUrlPermissions}/groups/${group}/${user}`
    );
  }
  getGroupRights(group: string): Observable<GroupRightsType> {
    return this.httpClient.get<GroupRightsType>(
      `${environment.restUrlPermissions}/groups/${group}/rights`
    );
  }
  getAllPermissions(): Observable<GroupRightsType> {
    return this.httpClient.get<GroupRightsType>(
      `${environment.restUrlPermissions}/permissions`
    );
  }
  updateRights(group: string, newRights: GroupRightsType): Observable<any> {
    return this.httpClient.put<GroupRightsType>(
      `${environment.restUrlPermissions}/groups/${group}/rights`,
      newRights
    );
  }
  addNewUser(userId: string): Observable<any> {
    console.log('userId', userId);
    return this.httpClient.put<GroupRightsType>(
      `${environment.restUrlPermissions}/users/${userId}`,
      {}
    );
  }
  addNewGroup(group: string): Observable<any> {
    return this.httpClient.put<GroupRightsType>(
      `${environment.restUrlPermissions}/groups/${group}/rights`,
      {}
    );
  }
  sendRequest(project: string, tab: string): Observable<any> {
    //return new Promise((resolve, reject) => {
    return this.httpClient.post<any>(
      `${environment.restUrlPermissions}/permissions/request`,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      { Tab: tab, Project: project }
    );
  }
}
