import { NgModule } from '@angular/core';
import { NgxsDispatchPluginModule } from '@ngxs-labs/dispatch-decorator';
import { NgxsEmitPluginModule } from '@ngxs-labs/emitter';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { NgxsRouterPluginModule } from '@ngxs/router-plugin';
import { NgxsModule } from '@ngxs/store';
import { environment } from '../environments/environment';
import { FeedbackState } from './secure/feedback/feedback.state';
import { PermissionsControlState } from './secure/permissions/permissions-control.state';
import { SelfServiceAiState } from './secure/self-service-ai/self-service-ai.state';
import { TraceabilityState } from './secure/traceability/traceability.state';
import { CloudInferenceState } from './secure/ai-cloud-inference/cloud-inference.state';

@NgModule({
  imports: [
    NgxsModule.forRoot(
      [
        SelfServiceAiState,
        TraceabilityState,
        FeedbackState,
        PermissionsControlState,
        CloudInferenceState
      ],
      {
        developmentMode: !environment.production,
      }
    ),
    NgxsLoggerPluginModule.forRoot({ logger: console, collapsed: false }),
    NgxsReduxDevtoolsPluginModule.forRoot({ disabled: environment.production }),
    NgxsEmitPluginModule.forRoot(),
    NgxsDispatchPluginModule.forRoot(),
    NgxsRouterPluginModule.forRoot(),
  ],
  exports: [NgxsModule],
})
export class NgxsStoreModule {}
