import { Injectable, Injector } from '@angular/core';
import { DateRange } from '@angular/material/datepicker';
import {
  Emittable,
  Emitter,
  EmitterAction,
  Receiver,
} from '@ngxs-labs/emitter';
import { Selector, State, StateContext } from '@ngxs/store';
import { switchMap, tap } from 'rxjs';
import { FeedbackDataService } from './services/feedback-data.service';
import { Page } from './types/page.type';
export interface FeedbackModel {
  loading: boolean;
  start: string | null;
  end: string | null;
  partIdFilter: string;
  selectedAiCase?: string;
  feedbackResponse?: any;
  pages: Page[];
  limit: number;
  lastrow: any;
  updatedRaw: any;
}

@State<FeedbackModel>({
  name: 'feedback',
  defaults: {
    loading: true,
    partIdFilter: '',
    pages: [
      { number: 1, lastrow: {} },
      { number: 2, lastrow: {} },
    ],
    start: null,
    end: null,
    limit: 20,
    lastrow: null,
    updatedRaw: null,
    selectedAiCase: '',
  },
})
@Injectable()
export class FeedbackState {
  static feedbackService: FeedbackDataService;
  @Emitter(FeedbackState.setLoading)
  private static emitLoading: Emittable<void>;
  @Emitter(FeedbackState.setLoadingFinished)
  private static emitLoadingFinished: Emittable<void>;
  @Emitter(FeedbackState.loadFeedback)
  private static emitLoadFeedback: Emittable<void>;
  constructor(injector: Injector) {
    FeedbackState.feedbackService =
      injector.get<FeedbackDataService>(FeedbackDataService);
  }
  @Selector()
  static loading(state: FeedbackModel): boolean {
    return state.loading;
  }

  @Selector()
  static updatedRaw(state: FeedbackModel): string[] {
    return state.updatedRaw;
  }
  @Selector()
  static pages(state: FeedbackModel): Page[] {
    return state.pages;
  }

  @Selector()
  static feedbackResponse(state: FeedbackModel): any | undefined {
    return state.feedbackResponse;
  }

  @Receiver()
  public static setDateRange(
    { patchState }: StateContext<FeedbackModel>,
    { payload }: EmitterAction<DateRange<string> | null>
  ) {
    const start = payload?.start;
    const end = payload?.end;

    patchState({ start: start, end: end });
    return this.emitLoadFeedback.emit();
  }

  @Receiver()
  public static setUpdatedRaw(
    { patchState }: StateContext<FeedbackModel>,
    { payload }: EmitterAction<any>
  ) {
    patchState({ updatedRaw: payload });
  }
  @Receiver()
  public static setPartIdFilter(
    { patchState }: StateContext<FeedbackModel>,
    { payload }: EmitterAction<string>
  ) {
    patchState({ partIdFilter: payload });
    return this.emitLoadFeedback.emit();
  }

  @Receiver()
  public static selectAiCase(
    { patchState }: StateContext<FeedbackModel>,
    { payload }: EmitterAction<string>
  ) {
    patchState({ selectedAiCase: payload });
    return this.emitLoadFeedback.emit();
  }
  @Receiver()
  public static lastrow(
    { patchState }: StateContext<FeedbackModel>,
    { payload }: EmitterAction<any>
  ) {
    patchState({ lastrow: payload });
    return this.emitLoadFeedback.emit();
  }
  @Receiver()
  public static pagesEmitter(
    { patchState }: StateContext<FeedbackModel>,
    { payload }: EmitterAction<Page[]>
  ) {
    patchState({ pages: payload });
  }
  @Receiver()
  public static feedbackResponseEmitter(
    { patchState }: StateContext<FeedbackModel>,
    { payload }: EmitterAction<any>
  ) {
    patchState({ feedbackResponse: payload });
  }
  @Receiver()
  public static sendFeedbackFormData(
    { getState, patchState }: StateContext<FeedbackModel>,
    { payload }: EmitterAction<any>
  ) {
    console.log('helloooo');
    return this.emitLoading.emit().pipe(
      switchMap(() =>
        this.feedbackService.sendFilledInFeedbackData(
          getState().selectedAiCase!,
          payload,
          getState().start,
          getState().end,
          getState().partIdFilter,
          getState().limit,
          getState().lastrow
        )
      ),
      tap((feedbackResponse) => patchState({ feedbackResponse })),
      switchMap(() => this.emitLoadingFinished.emit())
    );
    //switchMap((feedbackResponse) => this.emitLoadFeedback.emit()),
    // switchMap(() => console.log('hi'))
  }

  @Receiver()
  public static setLoading({ patchState }: StateContext<FeedbackModel>) {
    patchState({ loading: true });
  }

  @Receiver()
  public static setLoadingFinished({
    patchState,
  }: StateContext<FeedbackModel>) {
    patchState({ loading: false });
  }

  @Receiver()
  public static loadFeedback({
    patchState,
    getState,
  }: StateContext<FeedbackModel>) {
    if (getState().selectedAiCase === undefined) {
      return;
    }
    return this.emitLoading.emit().pipe(
      switchMap(() =>
        this.feedbackService.getOpenFeedbackData(
          getState().selectedAiCase!,
          getState().start,
          getState().end,
          getState().partIdFilter,
          getState().limit,
          getState().lastrow
        )
      ),
      tap((feedbackResponse) => patchState({ feedbackResponse })),
      switchMap(() => this.emitLoadingFinished.emit())
    );
  }
}
