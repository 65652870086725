import { Injectable, Injector } from '@angular/core';
import { DateRange } from '@angular/material/datepicker';
import {
  Emittable,
  Emitter,
  EmitterAction,
  Receiver,
} from '@ngxs-labs/emitter';
import { Selector, State, StateContext } from '@ngxs/store';
import { switchMap, tap } from 'rxjs/operators';
import { CloudInferenceDataService } from './services/cloud-inference-data.service';

export interface CloudInferenceModel {
  loading: boolean;
  start: string | null;
  end: string | null;
  partIdFilter: string;
  cloudInferenceResponse?: any;
  jobName?: string;
}
@State<CloudInferenceModel>({
  name: 'cloudInference',
  defaults: {
    loading: true,
    partIdFilter: '',
    start: null,
    end: null,
    jobName: '',
  },
})
@Injectable()
export class CloudInferenceState {
  static cloudInferenceService: CloudInferenceDataService;

  @Emitter(CloudInferenceState.setLoading)
  private static emitLoading: Emittable<void>;

  @Emitter(CloudInferenceState.setLoadingFinished)
  private static emitLoadingFinished: Emittable<void>;

  @Emitter(CloudInferenceState.loadCloudInference)
  private static emitLoadCloudInference: Emittable<void>;

  constructor(injector: Injector) {
    CloudInferenceState.cloudInferenceService =
      injector.get<CloudInferenceDataService>(CloudInferenceDataService);
  }

  @Receiver()
  public static setLoading({ patchState }: StateContext<CloudInferenceModel>) {
    patchState({ loading: true });
  }

  @Receiver()
  public static setLoadingFinished({
    patchState,
  }: StateContext<CloudInferenceModel>) {
    patchState({ loading: false });
  }
  @Receiver()
 public static loadCloudInference({ patchState, getState }: StateContext<CloudInferenceModel>) {
    return this.emitLoading.emit()
      .pipe(
        switchMap(() => this.cloudInferenceService.getData(
          getState().start,
          getState().end,
          getState().partIdFilter
        )),
        tap(cloudInferenceResponse => patchState({ cloudInferenceResponse })),
        switchMap(() => this.emitLoadingFinished.emit())
      );
  }
  @Receiver()
  public static setPartIdFilter(
    { patchState }: StateContext<CloudInferenceModel>,
    { payload }: EmitterAction<string>
  ) {
    patchState({ partIdFilter: payload });
    return this.emitLoadCloudInference.emit();
  }

  @Receiver()
  public static setLoadingTrue(
    { patchState }: StateContext<CloudInferenceModel>,
    { payload }: EmitterAction<boolean>
  ) {
    patchState({ loading: payload });
    return this.emitLoadCloudInference.emit();
  }

  @Receiver()
  public static setDateRange(
    { patchState }: StateContext<CloudInferenceModel>,
    { payload }: EmitterAction<DateRange<string> | null>
  ) {
    if (payload == undefined) {
      patchState({ start: null, end: null });
    } else {
      const start = payload?.start;
      const end = payload?.end;

      patchState({ start: start, end: end });
    }
    return this.emitLoadCloudInference.emit();
  }

  @Selector()
  static cloudInferenceResponse(state: CloudInferenceModel): any | undefined {
    return state.cloudInferenceResponse;
  }

  @Selector()
  static jobName(state: CloudInferenceModel): any | undefined {
    return state.jobName;
  }
  @Selector()
  static loading(state: CloudInferenceModel): any | undefined {
    return state.loading;
  }


}
