/* eslint-disable @typescript-eslint/naming-convention */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Auth } from 'aws-amplify';
import * as S3 from 'aws-sdk/clients/s3';
import { from, map, Observable, switchMap, zip } from 'rxjs';
import { environment } from 'src/environments/environment';
import { S3File } from '../types/s3-file.type';
function mapS3FolderToS3File(
  prefix: string,
  s3Folder: string,
  allowedProjects: string[]
): S3File {
  const hasAccess =
    prefix !== '' ||
    allowedProjects.some((project) => s3Folder.startsWith(project));
  const s3File: S3File = {
    key: s3Folder,
    name: s3Folder.replace(prefix, ''),
    isFolder: true,
    hasAccess,
  };
  return s3File;
}
function mapS3ObjectToS3File(prefix: string, item: S3.Object): S3File {
  const s3File: S3File = {
    key: item.Key!,
    name: item.Key!.replace(prefix, ''),
    size: item.Size! / 1024,
    lastModified: item.LastModified!,
    isFolder: false,
    hasAccess: true, // when a file is visible it's always accessible in the UI
  };
  return s3File;
}
type AccessMethod = 'GET' | 'PUT' | 'DELETE' | 'POST';
@Injectable({
  providedIn: 'root',
})
export class S3FileService {
  constructor(private httpClient: HttpClient) {}
  loadFilesAndFolders(
    bucket: string,
    prefix: string,
    allowedProjects: string[]
  ): Observable<S3File[]> {
    const auth: Promise<any> = Auth.currentCredentials();
    return from(auth).pipe(
      switchMap((creds: any) => {
        const s3 = new S3({ credentials: creds });
        const request = s3
          .listObjectsV2({ Bucket: bucket, Prefix: prefix, Delimiter: '/' })
          .promise();
        return request;
      }),
      map((res) => {
        const filesAndFolders = res.CommonPrefixes!.map((item) =>
          mapS3FolderToS3File(prefix, item.Prefix!, allowedProjects)
        );
        const files = res
          .Contents!.map((item) => mapS3ObjectToS3File(prefix, item))
          .filter((item) => item.name !== '');
        filesAndFolders.push(...files);
        return filesAndFolders;
      })
    );
  }
  uploadFiles(bucket: string, prefix: string, files: File[]): Observable<any> {
    const uploadResponses = files.map((file) => {
      return this.createPresignedUrl(
        bucket,
        prefix + file.name,
        'PUT',
        file.type
      ).pipe(
        switchMap((presignedUrl) =>
          fetch(presignedUrl, { method: 'PUT', body: file })
        )
      );
    });
    return zip(uploadResponses);
  }
  deleteFilesByPrefix(bucket: string, prefix: string): Observable<any> {
    const auth: Promise<any> = Auth.currentCredentials();
    return from(auth).pipe(
      map((creds) => new S3({ credentials: creds })),
      switchMap((s3) =>
        s3.listObjectsV2({ Bucket: bucket, Prefix: prefix }).promise()
      ),
      map((listResult) =>
        listResult.Contents!.map((item) =>
          this.createPresignedUrl(bucket, item.Key!, 'DELETE')
        )
      ),
      switchMap((presignedUrlsObservable) => zip(presignedUrlsObservable)),
      switchMap((presignedUrls) =>
        presignedUrls.map((presignedUrl) =>
          fetch(presignedUrl, { method: 'DELETE' })
        )
      ),
      switchMap((response) => zip(response))
    );
  }
  downloadFilesByPrefix(bucket: string, prefix: string): Observable<any> {
    const auth: Promise<any> = Auth.currentCredentials();
    return from(auth).pipe(
      map((creds) => new S3({ credentials: creds })),
      switchMap((s3) =>
        s3.listObjectsV2({ Bucket: bucket, Prefix: prefix }).promise()
      ),
      map((listResult) =>
        listResult.Contents!.map((item) => {
          if (item['Key']) {
            this.downloadContent(bucket, item['Key']).subscribe(
              (blobContent) => {
                if (item['Key']) {
                  this.downloadBlob(
                    blobContent,
                    item['Key'].split('/').slice(-1).join('')
                  );
                }
              }
            );
          }
        })
      )
    );
  }
  private downloadBlob(blob: any, filename: string) {
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename || 'download';
    const clickHandler = () => {
      setTimeout(() => {
        URL.revokeObjectURL(url);
        a.removeEventListener('click', clickHandler);
      }, 150);
    };
    a.addEventListener('click', clickHandler, false);
    a.click();
    console.log('from download blob test', a);
    return a;
  }
  retrainFilesByPrefix(bucket: string, key: string): Observable<string> {
    return this.httpClient.post<string>(
      `${environment.restUrlSelfServiceAi}/retrain`,
      {
        method: 'POST',
        bucket: bucket,
        key: key,
      }
    );
  }

  createSubdirectory(bucket: string, subdirKey: string): Observable<any> {
    // create presigned url by bucket + subdirKey:
    return this.createPresignedUrl(bucket, subdirKey, 'PUT').pipe(
      switchMap((presignedUrl) =>
        fetch(presignedUrl, { method: 'PUT', body: null })
      )
    );
  }
  downloadContent(bucket: string, objectKey: string): Observable<Blob> {
    const result = this.createPresignedUrl(bucket, objectKey, 'GET').pipe(
      switchMap((presignedUrl) => fetch(presignedUrl, { method: 'GET' })),
      switchMap((response) => response.blob())
    );
    return result;
  }
  private createPresignedUrl(
    bucket: string,
    objectKey: string,
    accessMethod: AccessMethod,
    contentType?: string
  ): Observable<string> {
    return this.httpClient.post<string>(
      `${environment.restUrlFeedbackData}/s3presigned`,
      {
        method: accessMethod,
        bucket: bucket,
        key: objectKey,
        contentType: contentType,
      }
    );
  }
}
