import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable, NgZone } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(private snackBar: MatSnackBar, private zone: NgZone) {}

  handleError(error: Error) {
    // Check if it's an error from an HTTP response
    if (!(error instanceof HttpErrorResponse)) {
      throw error;
    }
    console.log(error);
    this.zone.run(() =>
      this.snackBar.open(
        'calling ' +
          error?.url +
          'caused error: ' +
          (JSON.stringify(error?.error) || 'Undefined client error'),
        'OK',
        {
          duration: 10000,
          panelClass: ['red-snackbar', 'login-snackbar'],
        }
      )
    );

    console.error('Error from global error handler', error);
  }
}
