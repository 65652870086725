import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class TraceabilityService {
  constructor(private httpClient: HttpClient) {}

  searchByBarcode(barcode: string): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.restUrlTraceability}/trace?barcode=${barcode}`
    );
  }

  getTraceabilityAiCases(): Observable<string[]> {
    const aiCases = ['Duncan'];

    const sortedAiCases = aiCases.sort((a, b) => a.localeCompare(b));
    return of(sortedAiCases);
  }
}
