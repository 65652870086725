import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { DatePipe } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class CloudInferenceDataService {
  private apiUrl = environment.restUrlVoidDetection + '/void_detection'; // Replace with your Lambda function URL

  constructor(private httpClient: HttpClient) {}

  getData(
    start: string | null,
    end: string | null,
    partId?: string
  ): Observable<any> {
    let queryParams = new HttpParams();
    if (partId) {
      queryParams = queryParams.set('job_name', partId);
    }

    if (start) {
      queryParams = queryParams.set('startdate', start);
    }
    if (end) {
      queryParams = queryParams.set('enddate', end);
    }
    queryParams = queryParams.set('project', 'void_detection');
    return this.httpClient.get<any>(
      `${environment.restUrlVoidDetection}/void_detection`,
      {
        params: queryParams,
      }
    );
  }
  putData(
    project: string,
    jobName: string,
    configRef: string,
    imageRef: string,
    timestamp: string,
    uuid: string,
    issuer?: string,
    description?: string
  ) {

    const data = {
      project: project,
      uuid: uuid,
      job_name: jobName,
      config_ref: configRef,
      image_ref: imageRef,
      timestamp: timestamp,
      issuer: issuer !== undefined ? issuer : 'UNKNOWN', 
      description: description !== undefined ? description : 'NOT PROVIDED', 
      // Add more parameters as needed
    };
    return this.httpClient.put<any>(
      `${environment.restUrlVoidDetection}/void_detection`,
        data,   
    );
  }
}
