import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  keyUser: boolean | undefined;
  constructor(private auth: AuthService, private router: Router) {}
  async canActivate() {
    if (await this.auth.isAuthenticated()) {
      return true;
    }
    this.router.navigate(['public']);
    console.log('Not authenticated.');
    return false;
  }
}
