import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, mergeMap, of, switchMap, tap } from 'rxjs';

import { environment } from '../../../../environments/environment';
import { GroupRightsType } from '../../permissions/types/group-rights-type';

@Injectable({ providedIn: 'root' })
export class FeedbackDataService {
  constructor(private httpClient: HttpClient) {}

  getOpenFeedbackData(
    aiCase: string,
    start: string | null,
    end: string | null,
    partId?: string,
    limit?: number,
    lastrow?: any
  ): Observable<any> {
    aiCase = aiCase.replaceAll('/', '%2F');
    let queryParams = new HttpParams();
    if (partId) {
      queryParams = queryParams.set('barcode', partId);
    }
    if (limit) {
      queryParams = queryParams.set('limit', limit);
    }

    if (start) {
      queryParams = queryParams.set('startdate', start);
    }
    if (end) {
      queryParams = queryParams.set('enddate', end);
    }
    if (lastrow) {
      queryParams = queryParams.set('lastrow', JSON.stringify(lastrow));
    }
    return this.httpClient.get<any>(
      `${environment.restUrlFeedbackData}/labeling/${aiCase}`,
      {
        params: queryParams,
      }
    );
  }

  sendFilledInFeedbackData1(
    aiCase: string,
    feedbackData: any,
    start: string | null,
    end: string | null,
    partId?: string,
    limit?: number,
    lastrow?: any
  ): Observable<any> {
    aiCase = aiCase.replaceAll('/', '%2F');
    this.deleteHiddenElements(feedbackData);
    let queryParams = new HttpParams();
    if (partId) {
      queryParams = queryParams.set('barcode', partId);
    }
    if (limit) {
      queryParams = queryParams.set('limit', limit);
    }

    if (start) {
      queryParams = queryParams.set('startdate', start);
    }
    if (end) {
      queryParams = queryParams.set('enddate', end);
    }
    if (lastrow) {
      queryParams = queryParams.set('lastrow', JSON.stringify(lastrow));
    }
    return this.httpClient.put<any>(
      `${environment.restUrlFeedbackData}/labeling/${aiCase}`,
      feedbackData
    );
  }
  getAllPermissions(): Observable<GroupRightsType> {
    return this.httpClient.get<GroupRightsType>(
      `${environment.restUrlPermissions}/permissions`
    );
  }

  sendFilledInFeedbackData(
    aiCase: string,
    feedbackData: any,
    start: string | null,
    end: string | null,
    partId?: string,
    limit?: number,
    lastrow?: any
  ): Observable<any> {
    console.log('entered function');
    aiCase = aiCase.replaceAll('/', '%2F');
    this.deleteHiddenElements(feedbackData);
    let queryParams = new HttpParams();
    if (partId) {
      queryParams = queryParams.set('barcode', partId);
    }
    if (limit) {
      queryParams = queryParams.set('limit', limit);
    }

    if (start) {
      queryParams = queryParams.set('startdate', start);
    }
    if (end) {
      queryParams = queryParams.set('enddate', end);
    }
    if (lastrow) {
      queryParams = queryParams.set('lastrow', JSON.stringify(lastrow));
    }
    console.log('response1');
    return this.httpClient
      .put<any>(
        `${environment.restUrlFeedbackData}/labeling/${aiCase}`,
        feedbackData
      )
      .pipe(
        mergeMap((response1) => {
          console.log('response1', response1);
          return this.httpClient
            .get<any>(`${environment.restUrlFeedbackData}/labeling/${aiCase}`, {
              params: queryParams,
            })
            .pipe(
              mergeMap((data2) => {
                console.log('got data now', data2);
                return of(data2);
              })
            );
        })
      );
  }

  deleteHiddenElements(data: any) {
    for (const raw of data['dataframe'])
      if (raw['id']) {
        delete raw['id']['hide'];
      }
  }
}
