import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgxsStoreModule } from './app.store';

import { ErrorHandler, NgModule } from '@angular/core';

import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthService } from './services/auth.service';
import { GlobalErrorHandler } from './services/global-error-handler.service';
import { SharedModule } from './shared/shared.module';
import { AuthInterceptor } from './authentication/services/auth.interceptor';

@NgModule({
  declarations: [AppComponent],
  imports: [
    SharedModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FontAwesomeModule,
    NgxsStoreModule,
    FormsModule, // TODO Ines: Delete FormsModule here, it is already imported indirectly by SharedModule
  ],
  providers: [
    AuthService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
