import { Injectable, Injector } from '@angular/core';
import {
  Emittable,
  Emitter,
  EmitterAction,
  Receiver,
} from '@ngxs-labs/emitter';
import { Selector, State, StateContext, Store } from '@ngxs/store';
import { from, switchMap, tap } from 'rxjs';
import { SelfServiceAiPermission } from '../permissions/types/self-service-ai-permission.type';
import { S3FileService } from './services/s3-file-service.service';
import { S3File } from './types/s3-file.type';

export interface SelfServiceAiModel {
  filesLoading: boolean;
  prefix: string;
  allowedBuckets?: string[];
  allowedProjects?: string[];
  permissions?: SelfServiceAiPermission;
  selectedBucket: string;
  filesAndFolders: S3File[];
}

@State<SelfServiceAiModel>({
  name: 'selfserviceaimodel',
  defaults: {
    filesLoading: true,
    selectedBucket: '',
    prefix: '',
    filesAndFolders: [],
  },
})
@Injectable()
export class SelfServiceAiState {
  static s3FileService: S3FileService;

  @Emitter(SelfServiceAiState.setFilesLoading)
  private static emitFilesLoading: Emittable<void>;

  @Emitter(SelfServiceAiState.setFilesLoadingFinished)
  private static emitFilesLoadingFinished: Emittable<void>;

  @Emitter(SelfServiceAiState.loadFilesAndFolders)
  private static emitLoadFilesAndFolders: Emittable<void>;

  constructor(injector: Injector, store: Store) {
    SelfServiceAiState.s3FileService =
      injector.get<S3FileService>(S3FileService);
  }

  @Selector()
  static selectedBucket(state: SelfServiceAiModel): string {
    return state.selectedBucket;
  }

  @Selector()
  static prefix(state: SelfServiceAiModel): string {
    return state.prefix;
  }

  @Selector()
  static filesAndFolders(state: SelfServiceAiModel): any[] {
    return state.filesAndFolders;
  }

  @Selector()
  static allowedBuckets(state: SelfServiceAiModel): string[] | undefined {
    return state.allowedBuckets;
  }

  @Selector()
  static filesLoading(state: SelfServiceAiModel): boolean {
    return state.filesLoading;
  }

  @Receiver()
  public static setPrefix(
    { patchState }: StateContext<SelfServiceAiModel>,
    { payload }: EmitterAction<string>
  ) {
    patchState({ prefix: payload });
    this.emitLoadFilesAndFolders.emit();
  }

  @Receiver()
  public static deleteByPrefix(
    { getState }: StateContext<SelfServiceAiModel>,
    { payload }: EmitterAction<string>
  ) {
    return this.emitFilesLoading.emit().pipe(
      switchMap(() =>
        from(
          this.s3FileService.deleteFilesByPrefix(
            getState().selectedBucket,
            payload
          )
        )
      ),
      switchMap(() => this.emitLoadFilesAndFolders.emit())
    );
  }
  @Receiver()
  public static retrainPrefix(
    { getState }: StateContext<SelfServiceAiModel>,
    { payload }: EmitterAction<string>
  ) {
    return this.emitFilesLoading.emit().pipe(
      switchMap(() =>
        from(
          this.s3FileService.retrainFilesByPrefix(
            getState().selectedBucket,
            payload
          )
        )
      ),
      switchMap(() => this.emitLoadFilesAndFolders.emit())
    );
  }

  @Receiver()
  public static uploadFiles(
    { getState }: StateContext<SelfServiceAiModel>,
    { payload }: EmitterAction<File[]>
  ) {
    return this.emitFilesLoading.emit().pipe(
      switchMap(() =>
        this.s3FileService.uploadFiles(
          getState().selectedBucket,
          getState().prefix,
          payload
        )
      ),
      switchMap(() => this.emitLoadFilesAndFolders.emit())
    );
  }

  @Receiver()
  public static setPermissions(
    { patchState }: StateContext<SelfServiceAiModel>,
    { payload }: EmitterAction<SelfServiceAiPermission>
  ) {
    if (payload) {
      patchState({
        permissions: payload,
        allowedBuckets: Object.keys(payload.bucketsAndProjects),
      });
    }
  }

  @Receiver()
  public static setFilesLoading({
    patchState,
  }: StateContext<SelfServiceAiModel>) {
    patchState({ filesLoading: true });
  }

  @Receiver()
  public static setFilesLoadingFinished({
    patchState,
  }: StateContext<SelfServiceAiModel>) {
    patchState({ filesLoading: false });
  }

  @Receiver()
  public static selectBucket(
    { patchState, getState }: StateContext<SelfServiceAiModel>,
    { payload }: EmitterAction<string>
  ) {
    patchState({
      selectedBucket: payload,
      allowedProjects: getState().permissions?.bucketsAndProjects[payload],
    });
    if (payload) {
      this.emitLoadFilesAndFolders.emit();
    }
  }

  @Receiver()
  public static loadFilesAndFolders({
    patchState,
    getState,
  }: StateContext<SelfServiceAiModel>) {
    return this.emitFilesLoading.emit().pipe(
      switchMap(() =>
        this.s3FileService.loadFilesAndFolders(
          getState().selectedBucket,
          getState().prefix,
          getState().allowedProjects!
        )
      ),
      // ),
      // map((filesAndFolders) =>
      //   filesAndFolders.filter(
      //     (file) =>
      //       !file.isFolder ||
      //       getState().allowedProjects?.some((project) =>
      //         file.key.startsWith(project)
      //       )
      //   )
      // ),
      tap((filesAndFolders) => patchState({ filesAndFolders })),
      switchMap(() => this.emitFilesLoadingFinished.emit())
    );
  }

  @Receiver()
  public static createSubdirectory(
    { getState }: StateContext<SelfServiceAiModel>,
    { payload }: EmitterAction<string>
  ) {
    return from(
      this.s3FileService.createSubdirectory(
        getState().selectedBucket,
        `${getState().prefix}${payload}/`
      )
    ).pipe(switchMap(() => this.emitLoadFilesAndFolders.emit()));
  }
}
