import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormControl,
  ValidationErrors,
} from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormlyFieldConfig, FormlyModule } from '@ngx-formly/core';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { ImageLinkTypeComponent } from 'src/app/shared/generic-template/image-link-type.component';
import { AngularMaterialModule } from './angular-material.module';
import { ModalComponent } from './modal/modal.component';

// TODO Ines: Use shared modules only for components/modules/... that are used in every other module
// Formly stuff is only used in Feedback Module at the moment.
// Create an own generic-forms-module maybe including the modal component
export function emailValidator(
  control: UntypedFormControl | any
): ValidationErrors | null {
  return /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(
    control.value
  )
    ? null
    : { email: true };
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export function EmailValidatorMessage(err: any, field: FormlyFieldConfig) {
  return `"${field?.formControl?.value}" is not a valid email address`;
}

export function minlengthValidationMessage(
  err: ValidationErrors,
  field: FormlyFieldConfig
) {
  return `Should have atleast ${field?.templateOptions?.minLength} characters`;
}

export function maxlengthValidationMessage(
  err: ValidationErrors,
  field: FormlyFieldConfig
) {
  return `This value should be less than ${field?.templateOptions?.maxLength} characters`;
}
@NgModule({
  declarations: [ModalComponent],
  imports: [
    AngularMaterialModule,
    MatFormFieldModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AngularMaterialModule,
    FlexLayoutModule,
    // TODO Ines: Remove those imports, create an own "GenericForms" Module and import it only in "Feedback" Module
    FormlyMaterialModule,
    FormlyModule.forRoot({
      types: [
        {
          name: 'imageLink',
          component: ImageLinkTypeComponent,
          defaultOptions: {
            templateOptions: {
              btnType: 'default',
              type: 'button',
            },
          },
        },
      ],
      extras: { lazyRender: true },
      validationMessages: [
        { name: 'required', message: 'This field is required' },
        { name: 'minlength', message: minlengthValidationMessage },
        { name: 'maxlength', message: maxlengthValidationMessage },
        { name: 'email', message: EmailValidatorMessage },
      ],
      validators: [{ name: 'ip', validation: emailValidator }],
    }),
  ],
  exports: [
    FormsModule,
    CommonModule, // TODO Ines: export CommonModule here as well, so that other
    ReactiveFormsModule,
    AngularMaterialModule,
    FlexLayoutModule,
  ],
})
export class SharedModule {}
