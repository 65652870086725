<h1 mat-dialog-title>Image View</h1>
<div mat-dialog-content>
  <ng-container *ngIf="!safeUrl; else showSpinner">
    <mat-spinner class="spinner" color="primary"></mat-spinner>
  </ng-container>
  <ng-template #showSpinner>
    <img [src]="safeUrl" alt="S3 Image" width="530" height="400">
  </ng-template>
  <div>
    <form [formGroup]="form">
      <formly-form
        (modelChange)="onSelected()"
        [model]="model"
        [fields]="fields"
        [form]="form"
      ></formly-form>
    </form>
  </div>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()">Cancel</button>
  <button
    mat-dialog-close
    mat-button
    [disabled]="saveButtondisabled"
    (click)="save()"
    cdkFocusInitial
  >
    Save
  </button>
</div>
