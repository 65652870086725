import { Injectable, Injector } from '@angular/core';
import {
  Emittable,
  Emitter,
  EmitterAction,
  Receiver,
} from '@ngxs-labs/emitter';
import { Selector, State, StateContext } from '@ngxs/store';
import { switchMap, tap } from 'rxjs';
import { PermissionsService } from './permissions.service';
import { GroupRightsType } from './types/group-rights-type';
import { SelfServiceAiPermission } from './types/self-service-ai-permission.type';

export interface PermissionsModel {
  keyUser: boolean;
  email: string;
  loading: boolean;
  feedbackAiCases: string[];
  traceabilityAiCases: string[];
  selfServiceAi?: SelfServiceAiPermission;
  groupsList: string[];
  selectedGroup: string;
  usersForGroup: string[];
  allUsersList: string[];
  groupRights: GroupRightsType;
  allPermissions: GroupRightsType;
  loadingUsers: boolean;
  loadingPermissions: boolean;
}
@State<PermissionsModel>({
  name: 'permissions',
  defaults: {
    email: '',
    keyUser: false,
    loading: false,
    feedbackAiCases: [],
    traceabilityAiCases: [],
    groupsList: [],
    selectedGroup: '',
    usersForGroup: [],
    allUsersList: [],
    // eslint-disable-next-line @typescript-eslint/naming-convention
    groupRights: { Labeling: [], Traceability: [], SelfService: {} },
    // eslint-disable-next-line @typescript-eslint/naming-convention
    allPermissions: { Labeling: [], Traceability: [], SelfService: {} },
    loadingUsers: false,
    loadingPermissions: false,
  },
})
@Injectable()
export class PermissionsControlState {
  @Emitter(PermissionsControlState.setLoading)
  private static emitLoading: Emittable<void>;

  @Emitter(PermissionsControlState.setLoadingFinished)
  private static emitLoadingFinished: Emittable<void>;

  @Emitter(PermissionsControlState.setLoadingUsers)
  private static emitLoadingUsers: Emittable<void>;

  @Emitter(PermissionsControlState.setLoadingUsersFinished)
  private static emitLoadingUsersFinished: Emittable<void>;

  @Emitter(PermissionsControlState.setLoadingPermissions)
  private static emitLoadingPermissions: Emittable<void>;

  @Emitter(PermissionsControlState.setLoadingPermissionsFinished)
  private static emitLoadingPermissionsFinished: Emittable<void>;

  @Emitter(PermissionsControlState.loadPermissions)
  private static emitLoadPermissions: Emittable<void>;

  @Emitter(PermissionsControlState.loadAllPermissions)
  private static emitLoadAllPermissions: Emittable<void>;
  @Emitter(PermissionsControlState.updateUsersList)
  private static emitupdateUsersList: Emittable<void>;
  @Emitter(PermissionsControlState.updateGroupRights)
  private static emitLoadGroupRights: Emittable<void>;
  @Emitter(PermissionsControlState.updateGroupsList)
  private static emitUpdateGroupsList: Emittable<void>;
  @Emitter(PermissionsControlState.updateGroupUsersList)
  private static emitLoadUsersForGroup: Emittable<void>;
  static permissionsService: PermissionsService;
  constructor(injector: Injector) {
    PermissionsControlState.permissionsService =
      injector.get<PermissionsService>(PermissionsService);
  }
  @Receiver()
  public static setLoading({ patchState }: StateContext<PermissionsModel>) {
    patchState({ loading: true });
  }
  @Receiver()
  public static setLoadingUsers({
    patchState,
  }: StateContext<PermissionsModel>) {
    patchState({ loadingUsers: true });
  }
  @Receiver()
  public static setLoadingUsersFinished({
    patchState,
  }: StateContext<PermissionsModel>) {
    patchState({ loadingUsers: false });
  }
  @Receiver()
  public static setLoadingPermissions({
    patchState,
  }: StateContext<PermissionsModel>) {
    patchState({ loadingPermissions: true });
  }
  @Receiver()
  public static setLoadingPermissionsFinished({
    patchState,
  }: StateContext<PermissionsModel>) {
    patchState({ loadingPermissions: false });
  }
  @Receiver()
  public static setGroupRights(
    { getState }: StateContext<PermissionsModel>,
    { payload }: EmitterAction<GroupRightsType>
  ) {
    return this.emitLoadingPermissions.emit().pipe(
      switchMap(() =>
        this.permissionsService.updateRights(getState().selectedGroup, payload)
      ),
      tap(() => {
        this.emitLoadGroupRights.emit();
      }),
      switchMap(() => this.emitLoadingPermissionsFinished.emit())
    );
  }

  @Receiver()
  public static setLoadingFinished({
    patchState,
  }: StateContext<PermissionsModel>) {
    patchState({ loading: false });
  }

  @Selector()
  static allPermissionsResponse(state: PermissionsModel): any | undefined {
    return state.allPermissions;
  }
  @Selector()
  static groupRights(state: PermissionsModel): any | undefined {
    return state.groupRights;
  }
  @Selector()
  static groupsList(state: PermissionsModel): any | undefined {
    return state.groupsList;
  }
  @Selector()
  static loading(state: PermissionsModel): boolean | undefined {
    return state.loading;
  }

  @Selector()
  static getKeyUser(state: PermissionsModel): any | undefined {
    return state.keyUser;
  }
  @Selector()
  static feedbackAiCases(state: PermissionsModel): any | undefined {
    return state.feedbackAiCases;
  }
  @Selector()
  static traceabilityAiCases(state: PermissionsModel): any | undefined {
    return state.traceabilityAiCases;
  }

  @Selector()
  static selfServiceAiPermissions(
    state: PermissionsModel
  ): undefined | SelfServiceAiPermission {
    return state.selfServiceAi;
  }
  @Selector()
  static getUsersForGroup(state: PermissionsModel): string[] {
    return state.usersForGroup;
  }
  @Selector()
  static getAllUsers(state: PermissionsModel): string[] {
    return state.allUsersList;
  }
  @Selector()
  static getSelectedGroup(state: PermissionsModel): string {
    return state.selectedGroup;
  }
  @Selector()
  static getLoadingUsers(state: PermissionsModel): boolean {
    return state.loadingUsers;
  }
  @Selector()
  static getLoadingPermissions(state: PermissionsModel): boolean {
    return state.loadingPermissions;
  }

  @Receiver()
  public static updateEmail(
    { patchState }: StateContext<PermissionsModel>,
    { payload }: EmitterAction<any>
  ) {
    patchState({ email: payload });
    return this.emitLoadPermissions.emit();
  }
  @Receiver()
  public static updatePermissions() {
    return this.emitLoadAllPermissions.emit();
  }
  @Receiver()
  public static updateUsersForGroupList(
    { patchState }: StateContext<PermissionsModel>,
    { payload }: EmitterAction<any>
  ) {
    patchState({ usersForGroup: payload });
  }
  @Receiver()
  public static updatekeyUser(
    { patchState }: StateContext<PermissionsModel>,
    { payload }: EmitterAction<any>
  ) {
    patchState({ keyUser: payload });
    return this.emitLoadPermissions.emit();
  }
  @Receiver()
  public static selectGroup(
    { patchState }: StateContext<PermissionsControlState>,
    { payload }: EmitterAction<string>
  ) {
    patchState({ selectedGroup: payload });

    this.emitLoadGroupRights.emit();
    return this.emitLoadUsersForGroup.emit();
  }
  @Receiver()
  public static loadPermissions({
    patchState,
    getState,
  }: StateContext<PermissionsModel>) {
    if (getState().email === undefined) {
      return;
    } else {
      return this.emitLoading.emit().pipe(
        switchMap(() =>
          this.permissionsService.getPermissions(getState().email)
        ),
        tap((permissions) => {
          patchState({
            traceabilityAiCases: permissions.Traceability,
            feedbackAiCases: permissions.Labeling,
            selfServiceAi: { bucketsAndProjects: permissions.SelfService },
          });
        }),
        switchMap(() => this.emitLoadingFinished.emit())
      );
    }
  }

  @Receiver()
  public static loadAllPermissions({
    patchState,
  }: StateContext<PermissionsModel>) {
    return this.emitLoading.emit().pipe(
      switchMap(() => this.permissionsService.getAllPermissions()),
      tap((permissions) => {
        patchState({
          allPermissions: permissions,
        });
      }),
      switchMap(() => this.emitLoadingFinished.emit())
    );
  }

  @Receiver()
  public static updateGroupsList({
    patchState,
  }: StateContext<PermissionsModel>) {
    return this.permissionsService.getGroupsList().subscribe((res) => {
      patchState({
        groupsList: res,
      });
    });
  }
  @Receiver()
  public static updateUsersList({
    patchState,
  }: StateContext<PermissionsModel>) {
    return this.emitLoadingUsers.emit().pipe(
      switchMap(() => this.permissionsService.getAllUsersList()),
      tap((users) => {
        patchState({
          allUsersList: users,
        });
      }),
      switchMap(() => this.emitLoadingUsersFinished.emit())
    );
  }

  @Receiver()
  public static updateGroupRights({
    getState,
    patchState,
  }: StateContext<PermissionsModel>) {
    return this.emitLoadingPermissions.emit().pipe(
      switchMap(() =>
        this.permissionsService.getGroupRights(getState().selectedGroup)
      ),
      tap((res) => {
        patchState({
          groupRights: res,
        });
      }),
      switchMap(() => this.emitLoadingPermissionsFinished.emit())
    );
  }
  @Receiver()
  public static updateGroupUsersList({
    patchState,
    getState,
  }: StateContext<PermissionsModel>) {
    return this.emitLoadingUsers.emit().pipe(
      switchMap(() =>
        this.permissionsService.getUsersInGroup(getState().selectedGroup)
      ),
      tap((users) => {
        patchState({
          usersForGroup: users,
        });
      }),
      switchMap(() => this.emitLoadingUsersFinished.emit())
    );
  }

  @Receiver()
  public static addUserToGroup(
    { getState }: StateContext<PermissionsModel>,
    { payload }: EmitterAction<string>
  ) {
    if (getState().selectedGroup === undefined) {
      return;
    } else {
      return this.emitLoadingUsers.emit().pipe(
        switchMap(() =>
          this.permissionsService.addUserToGroup(
            payload,
            getState().selectedGroup
          )
        ),
        switchMap(() => this.emitLoadUsersForGroup.emit()),
        switchMap(() => this.emitLoadingUsersFinished.emit())
      );
    }
  }
  @Receiver()
  public static deleteUserFromGroup(
    { getState }: StateContext<PermissionsModel>,
    { payload }: EmitterAction<string>
  ) {
    if (getState().selectedGroup === undefined) {
      return;
    } else {
      return this.emitLoadingUsers.emit().pipe(
        switchMap(() =>
          this.permissionsService.deleteUserFromGroup(
            payload,
            getState().selectedGroup
          )
        ),
        switchMap(() => this.emitLoadUsersForGroup.emit()),
        switchMap(() => this.emitupdateUsersList.emit()),
        switchMap(() => this.emitLoadUsersForGroup.emit()),
        switchMap(() => this.emitLoadingUsersFinished.emit())
      );
    }
  }
  @Receiver()
  public static addGroup(
    { getState, patchState }: StateContext<PermissionsModel>,
    { payload }: EmitterAction<string>
  ) {
    {
      return this.permissionsService
        .addNewGroup(payload)
        .pipe(switchMap(() => this.emitUpdateGroupsList.emit()));
    }
  }
  @Receiver()
  public static sendRequest(
    { getState }: StateContext<PermissionsModel>,
    { payload }: EmitterAction<string[]>
  ) {
    return this.emitLoading.emit().pipe(
      switchMap(() =>
        this.permissionsService.sendRequest(payload[1], payload[0])
      ),

      switchMap(() => this.emitLoadingFinished.emit())
    );
  }
}
