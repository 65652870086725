const hasLocalhost = (hostname: string) =>
  Boolean(
    hostname.match(/localhost/) ||
      hostname.match(/127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}/)
  );
const isLocalhost = hasLocalhost(window.location.hostname);
export const environment = {
  restUrl: '',
  restUrlFeedbackData:
    'https://sfrcwypje0.execute-api.eu-west-1.amazonaws.com/prod',
  restUrlTraceability:
    'https://5xkf3qwhfl.execute-api.eu-west-1.amazonaws.com/prod',
  restUrlSelfServiceAi:
    'https://can2otm0s9.execute-api.eu-west-1.amazonaws.com/prod',
  restUrlPermissions:
    'https://ix19yirxqk.execute-api.eu-west-1.amazonaws.com/prod',
  restUrlVoidDetection:
    'https://c8wu4ri09d.execute-api.eu-west-1.amazonaws.com/prod',
  cloudInferenceResultsBucketName: 'stob-awseur-ai-cloud-inference-results-dev',
  licensesUrl: '/assets',
  assetsBucketName: 'draexlmaier-datatransfer-dun-teststation1',
  roiToolBucketLink:'s3://stob-awseur-ai-cloud-inference-results-dev/documentation/void_detection/AIVoidDetectionTool.html',
  documentationBucketLink:'s3://stob-awseur-ai-cloud-inference-results-dev/documentation/void_detection/Automatic AI Void Detection - User Documentation.pdf',
  stage: isLocalhost ? 'local' : 'dev',
  version: require('../../package.json').version,
  production: false,

  amplify: {
    Storage: {
      AWSS3: {
        bucket: 'draexlmaier-datatransfer-sou-g2y-teststation1',
        region: 'eu-west-1', // Specify the AWS region here
      },
    },
    Auth: {
      //after checking the old app, the identity pool ID is not the same
      identityPoolId: 'eu-west-1:223d9e7b-546f-41ee-bb73-4dc566f629cc',
      region: 'eu-west-1',
      identityPoolRegion: 'eu-west-1',
      userPoolId: 'eu-west-1_WFwXPWs9D',
      userPoolWebClientId: '4r9es64dpjtcn0i6v4podk57pg',
      oauth: {
        domain: 'draexlmaier-dt-predqual.auth.eu-west-1.amazoncognito.com',
        redirectSignIn: isLocalhost
          ? 'http://localhost:4200/secure'
          : 'https://self-service-ai.dev.predqual.draexlmaier.solutions/secure',
        redirectSignOut: isLocalhost
          ? 'http://localhost:4200/public'
          : 'https://self-service-ai.dev.predqual.draexlmaier.solutions/public',

        responseType: 'code',
        scope: [
          'phone',
          'email',
          'openid',
          'profile',
          'aws.cognito.signin.user.admin',
        ],
      },
    },
  },
};
