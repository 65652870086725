import { Component, OnInit } from '@angular/core';
import { Emittable, Emitter } from '@ngxs-labs/emitter';
import { PermissionsControlState } from './secure/permissions/permissions-control.state';
import { AuthService } from './services/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
  title = 'ui';
  @Emitter(PermissionsControlState.updateGroupsList) getGroupsList:
    | Emittable<any>
    | undefined;

  @Emitter(PermissionsControlState.updateEmail) updateEmail:
    | Emittable<any>
    | undefined;

  constructor(private authService: AuthService) {}
  async ngOnInit() {
    const email = await this.authService.getEmail();
    this.updateEmail?.emit(email);
  }
}
